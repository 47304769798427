
/*
	-----------------------------------------
	Variables
	-----------------------------------------
*/

/* fonts */

$mainColor: #5A6771;
$linkColor: #FF9E16;
$varcolor1 : #5A6771;
$varcolor2 : #A1D0CA;
$grayColor: #EDEDED;


$font: 'Raleway', Arial, Helvetica,sans-serif;
$titlefont: 'Raleway', Arial, Helvetica, sans-serif;

/* breakpoints */

$smallest: 400px;
$mobile: 680px;
$tab : 768px;
$smalltablet: 767px;
$desk: 900px;
$ipad: 1024px;

::-moz-selection {background: $linkColor; color: #eee;}
::selection {background: $linkColor; color: #eee;}
