/*
	-----------------------------------------
	Project name: title
	Company: Lavagraphics
	Author: Brecht De Ruyte
	-----------------------------------------
*/
/*
	-----------------------------------------
	Print styles
	-----------------------------------------
*/

@import "partials/vars";
@import "partials/mixins";

/* make text black - bg white */
* {
  color: #000 !important;
  float: none;
}

div {
  background-color: white !important;
}

#printlogo {
  display: block;
  float: left;
  clear: both;
}

/* hide the divs */
nav, form, .sidebar {
  display: none;
}

body {
  font-family: $font;
  font-size: 10pt;
  background: #FFF;
  border: none;
}

p {
  padding: 0 0 20px 0;
}

ul, ol {
  padding: 0 0 20px 30px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 18pt;
  padding: 0 0 10px 0;
}

h2 {
  font-size: 16pt;
  padding: 0 0 8px 0;
}

h3 {
  font-size: 14pt;
  padding: 0 0 6px 0;
}

h4 {
  font-size: 12pt;
  padding: 0 0 6px 0;
}

blockquote {
  padding-left: 2.5cm;
  font-style: italic;
}

table {
  font-family: Arial, sans-serif;
  margin: 0 10px 20px;
  border-top: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
}

table td, th {
  padding: 5px 10px;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

table th {
  font-weight: 700;
  background: #D9D9D9;
  color: #000;
}

address {
  font-style: normal;
  span {
    display: block;
  }
}